<template>
  <div class="tabs" v-if="loaded">
    <div
      v-for="(item, index) in formattedTabs"
      :key="index"
      :class="{ active: item.active }"
      @click="selectTab(index)"
    >{{ item.title }}</div>
  </div>
</template>

<!--Artworks Description Activity-->

<script>
export default {
  name: "Tabs",
  data: () => ({
    loaded: false,
    formattedTabs: [],
  }),
  props: {
    activeTab: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  watch: {
    activeTab() {
      this.init();
    },
  },
  methods: {
    selectTab(index) {
      this.formattedTabs.forEach((i) => (i.active = false));
      this.formattedTabs[index].active = true;
      this.$emit("change", index);
    },
    init() {
      this.loaded = false;
      this.formattedTabs = [];
      this.items.forEach((i) =>
        this.formattedTabs.push({
          title: i,
        })
      );
      this.formattedTabs.forEach((i) => this.$set(i, "active", false));
      this.formattedTabs[this.activeTab].active = true;
      this.loaded = true;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
