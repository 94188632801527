<template>
  <div v-if="user">
    <div id="intro">
      <div class="cover" v-if="banner">
        <img :src="banner" />
      </div>

      <div class="section">
        <div class="meta">
          <div class="userpic">
            <img
              v-if="!isOwnerProfile"
              v-once
              :src="appointAvatar(avatar, id)"
            />
            <img v-else :src="getAvatar" />
          </div>

          <div class="tools">
            <div
              class="report"
              @click="showReportModal = true"
              v-if="!isOwnerProfile"
            >
              Report
            </div>

            <div
              class="svg share"
              :class="{ active: shareSocial }"
              @click="shareSocial = !shareSocial"
              v-click-outside="hide"
            >
              <svg><use xlink:href="#svg-share" /></svg>

              <div class="drop">
                <ShareNetwork
                  class="svg"
                  :class="item.network.toLowerCase()"
                  v-for="(item, index) in shareNetworks"
                  :key="index"
                  :network="item.network"
                  :url="shareUrl"
                  title="Have a look at this account on QNFT"
                  hashtags="QNFT"
                >
                  <svg>
                    <use :xlink:href="`#svg-${item.network.toLowerCase()}`" />
                  </svg>
                </ShareNetwork>
              </div>
            </div>

            <router-link
              :to="settingsLink()"
              class="svg edit"
              v-if="isOwnerProfile"
            >
              <svg><use xlink:href="#svg-edit" /></svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--[ #intro ]-->

    <div id="subintro" class="section">
      <h1 v-if="fullName !== ' '">{{ truncate(fullName, 60) }}</h1>

      <div class="flex">
        <div class="userinfo">
          <UsernameLink
            :username="nickname"
            :user-id="id"
            :user-address="address"
          />

          <div class="wallet" :data="address">
            <div class="mono">{{ address | cutHash }}</div>
            <span
              class="svg"
              @click="doCopy(address)"
              :class="isCopied ? 'done' : 'copy'"
            >
              <svg>
                <use :xlink:href="`#svg-${isCopied ? 'done' : 'copy'}`" />
              </svg>
            </span>
          </div>

          <div>Joined {{ timestampToCustomString(userCreated, "short") }}</div>
        </div>

        <div class="following">
          <div>
            <div>{{ following }}</div>
            <div>Following</div>
          </div>
          <div>
            <div>{{ followers }}</div>
            <div>Followers</div>
          </div>

          <div
            class="button"
            @click="subscribe"
            v-if="!isFollow && !isOwnerProfile"
          >
            Follow
          </div>

          <div
            class="button"
            @click="unsubscribe"
            v-if="isFollow && !isOwnerProfile"
          >
            Unfollow
          </div>
        </div>

        <!-- <div class="links"></div> -->
      </div>

      <Tabs
        v-if="tab != null"
        :items="tabs"
        :active-tab="tab"
        @change="selectTab"
      />
    </div>
    <!--[ #subintro ]-->

    <div class="section" v-if="tab === 0">
      <div id="bio">
        <p v-if="description">{{ description }}</p>
        <p v-else>This user hasn’t added any bio information yet.</p>
      </div>
    </div>

    <div id="stack" class="tiles section" v-if="tab !== 0">
      <div class="flex">
        <TokenCard v-for="(item, index) in items" :key="index" :token="item" />
      </div>
      <ScrollLoader
        :loader-method="getLoaderMethod"
        :loader-disable="disableLoading"
      >
        <img width="200" height="200" src="@/assets/img/preloader.svg" />
      </ScrollLoader>
      <NoRecordsFound v-if="!items.length && !loading" />
    </div>

    <ReportModal
      v-if="showReportModal"
      @close="showReportModal = false"
      :creator-id="id"
    />
  </div>
</template>

<script>
import Tabs from "@/components/common/Tabs";
import api from "@/api/api";
import TokenCard from "@/components/common/TokenCard";
import capitalizeFirstLetterMixin from "@/mixins/capitalizeFirstLetterMixin";
import truncateMixin from "@/mixins/truncateMixin";
import cutHashMixin from "@/mixins/cutHashMixin";
import UsernameLink from "@/components/common/UsernameLink";
import userInfoMixin from "@/mixins/userInfoMixin";
import appointAvatarMixin from "@/mixins/appointAvatarMixin";
import timestampToCustomStringMixin from "@/mixins/timestampToCustomStringMixin";
import doCopyMixin from "@/mixins/doCopyMixin";
import { mapGetters, mapMutations } from "vuex";
import NoRecordsFound from "@/components/common/NoRecordsFound";
import resetPrevLoadingMixin from "@/mixins/resetPrevLoadingMixin";
import getIsAuthorizedMixin from "@/mixins/getIsAuthorizedMixin";
import clickOutsideMixin from "@/mixins/clickOutsideMixin";
import ReportModal from "@/components/modals/ReportModal";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import errors from "@/util/errors";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "Profile",
  mixins: [
    capitalizeFirstLetterMixin,
    truncateMixin,
    cutHashMixin,
    userInfoMixin,
    appointAvatarMixin,
    timestampToCustomStringMixin,
    doCopyMixin,
    resetPrevLoadingMixin,
    getIsAuthorizedMixin,
    clickOutsideMixin,
    setLoadingMixin,
    useLinkMixin,
  ],
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    shareUrl: window.location.href,
    showReport: false,
    showReportModal: false,
    shareNetworks: [
      {
        network: "telegram",
      },
      {
        network: "twitter",
      },
      {
        network: "Facebook",
      },
    ],
    shareSocial: false,
    tabs: ["Bio", "Owned", "Created"],
    items: [],
    page: 0,
    size: 8,
    tab: null,
    user: null,
    following: 0,
    followers: 0,
    isFollow: false,
    loading: false,
    disableLoading: false,
  }),
  computed: {
    ...mapGetters({
      getId: "user/getId",
      getAvatar: "user/getAvatar",
    }),
    getLoaderMethod() {
      switch (this.tab) {
        case 0:
          break;
        case 1:
          return this.getOwnNft;
        case 2:
          return this.getCreatedNft;
      }
      return null;
    },
    isOwnerProfile() {
      return this.getId === +this.id;
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  methods: {
    ...mapMutations({
      setError: "general/setError",
    }),
    hideReport() {
      this.showReport = false;
    },
    hide() {
      this.shareSocial = false;
    },
    selectTab(index) {
      this.resetPrevLoading();
      this.tab = index;
      this.$router.push(
        this.profileLink(this.id, this.tabs[index].toLowerCase())
      );
    },
    async getCreatedNft() {
      try {
        this.loading = true;
        const response = await api.getCreatedNft({
          page: this.page++,
          size: this.size,
          sort: "created,desc",
          userId: this.id,
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch {
        this.disableLoading = true;
      } finally {
        this.loading = false;
      }
    },
    async getOwnNft() {
      try {
        this.loading = true;
        const response = await api.getOwnNft({
          page: this.page++,
          size: this.size,
          sort: "created,desc",
          userId: this.id,
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch {
        this.disableLoading = true;
      } finally {
        this.loading = false;
      }
    },
    async getUserById() {
      try {
        this.user = await api.getUserById({
          userId: this.id,
        });
      } catch {
        this.user = null;
      }
    },
    async getSubscribesInfo() {
      try {
        const response = await api.getSubscribesInfo({
          subId: this.id,
        });
        this.followers = response.followerCount;
        this.following = response.followingCount;
        this.isFollow = response.follow;
      } catch {
        this.followers = 0;
        this.following = 0;
        this.isFollow = false;
      }
    },
    async subscribe() {
      if (!this.getIsAuthorized) {
        this.setError(errors.NEED_LOGIN);
        return;
      }
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        await api.subscribe({
          subId: this.id,
        });
        this.isFollow = true;
        this.followers++;
      } finally {
        this.loading = false;
      }
    },
    async unsubscribe() {
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        await api.unsubscribe({
          subId: this.id,
        });
        this.isFollow = false;
        this.followers--;
      } finally {
        this.loading = false;
      }
    },
    async init() {
      this.setLoading(true);
      this.tab = this.tabs.indexOf(this.capitalizeFirstLetter(this.currentTab));
      await Promise.all([this.getUserById(), this.getSubscribesInfo()]);
      this.setLoading(false);
    },
  },
  async mounted() {
    await this.init();
  },
  components: { ReportModal, NoRecordsFound, UsernameLink, TokenCard, Tabs },
};
</script>
