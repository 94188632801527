export default {
  methods: {
    validateRegex(value, regex) {
      if (!value) {
        return true;
      }
      return String(value).toLowerCase().match(regex);
    },
  },
};
