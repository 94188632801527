<template>
  <div class="popup">
    <div class="popup-overlay" @click="close"></div>

    <div class="popup-container">
      <div class="svg close" @click="close">
        <svg><use xlink:href="#svg-close" /></svg>
      </div>

      <div class="popup-content">
        <h3>Submit a report</h3>
        <p>If&nbsp;you believe there’s been a&nbsp;violation of&nbsp;DisplayNFT’s Terms of&nbsp;Service, please complete this report.</p>

        <div class="fel">
          <input type="email" name="email" placeholder="Your email" v-model="replyMailComputed" />
          <div class="fel-error">{{ emailError }}</div>
        </div>

        <div class="fel">
          <textarea name="description" placeholder="Description" v-model="descriptionComputed"></textarea>
          <div class="fel-error">{{ descriptionError }}</div>
        </div>

        <button type="button" class="green" :disabled="disableSubmitBtn" @click="sendReport">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalMixin from "@/mixins/closeModalMixin";
import api from "@/api/api";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import validateRegexMixin from "@/mixins/validateRegexMixin";
import getIsAuthorizedMixin from "@/mixins/getIsAuthorizedMixin";
import errors from "@/util/errors";
import successes from "@/util/successes";

export default {
  name: "ReportModal",
  mixins: [
    CloseModalMixin,
    setProcessingMixin,
    validateRegexMixin,
    getIsAuthorizedMixin,
  ],
  props: {
    creatorId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    description: null,
    replyMail: null,
    emailError: null,
    descriptionError: null,
  }),
  computed: {
    disableSubmitBtn() {
      return (
        !this.description ||
        !this.replyMail ||
        this.emailError ||
        this.descriptionError
      );
    },
    descriptionComputed: {
      get() {
        return this.description;
      },
      set(val) {
        this.descriptionError = null;
        if (val && val.length > 500) {
          this.descriptionError = `${this.$t(errors.MAX_LENGTH_ERROR)} 500`;
        }
        if (!val) {
          this.descriptionError = this.$t(errors.REQUIRED_FIELD);
        }
        this.description = val;
      },
    },
    replyMailComputed: {
      get() {
        return this.replyMail;
      },
      set(val) {
        this.emailError = null;
        const regex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.validateRegex(val, regex)) {
          this.emailError = errors.INVALID_EMAIL;
        }
        if (!val) {
          this.emailError = this.$t(errors.REQUIRED_FIELD);
        }
        this.replyMail = val;
      },
    },
  },
  methods: {
    validate() {
      if (!this.getIsAuthorized) {
        this.setError(errors.NEED_LOGIN);
        return false;
      }
      if (!this.description) {
        this.descriptionError = this.$t(errors.REQUIRED_FIELD);
        return false;
      }
      if (!this.replyMail) {
        this.emailError = this.$t(errors.REQUIRED_FIELD);
        return false;
      }
      return true;
    },
    async sendReport() {
      try {
        if (!this.validate()) {
          return;
        }
        this.setLoading(true);
        await api.sendReport({
          creatorId: this.creatorId,
          description: this.description,
          nftTokenId: null,
          replyMail: this.replyMail,
          type: "CREATOR",
        });
        this.setSuccess(successes.REPORT_SUBMITTED);
        this.setLoading(false);
        this.close();
      } catch (e) {
        this.setError(e.response.data.code);
        this.setLoading(false);
      }
    },
  },
};
</script>
